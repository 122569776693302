/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
:root {
    --white: #fff;
    --primary: #2C8A55; // #EE9315
    --secondary: #1f2026; // #2C8A55
    --bg-color: #f3f4f8;
    --transparent: rgba(0, 0, 0, 0);
    --text-dark: #747474;
    --text-black: #0e0e0f;
    --text-light: #c2c2c2;
}

.bg_none {
    --background: var(--transparent) !important;
}

.bg_color {
    --background: var(--bg-color);
}

ion-item {
    --ripple-color: var(--transparent) !important;
}

.scroll_container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.d-flex {
    display: flex !important;
    align-items: center;
    width: 100%;
    .end {
        margin-left: auto;
        margin-right: 0;
        display: block;
    }
}

.center_img {
    position: relative;
    overflow: hidden;
    img {
        &.crop_img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: -50% !important;
            right: -50% !important;
            margin: auto;
        }
    }
}

.button {
    &.btn {
        width: 100%;
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 0px !important;
        height: 50px;
        --background: var(--primary);
        text-transform: none;
        color: var(--white);
        --border-radius: 0px;
        margin: 0;
        --box-shadow: none;
        ion-icon {
            font-size: 1.4rem;
        }
        &:hover:not(.disable-hover) {
            --background: var(--primary);
        }
        &.button-outline {
            --background: var(--transparent);
            color: var(--white);
            --border-color: var(--primary);
            --border-width: 1px;
            &:hover:not(.disable-hover) {
                --background: var(--transparent);
            }
        }
    }
}

ion-header {
    z-index: 999;
    &::after {
        display: none !important;
    }
    ion-toolbar {
        --background: var(--white);
        display: flex;
        align-items: center;
        min-height: 60px !important;
        height: auto;
        overflow: visible !important;
        ion-buttons {
            position: relative !important;
            margin: 0;
            --padding-end: 0 !important;
            --padding-start: 0 !important;
            .button-inner {
                ion-icon {
                    //font-size: 1.5rem;
                    padding-left: 10px;
                    color: var(--primary);
                }
                .button-text {
                    display: none;
                }
            }
            ion-menu-button {
                --color: var(--primary) !important;
                //--padding-start: 0 !important;
                --padding-end: 0 !important;
                font-size: 1.8rem !important;
                &.ios {
                    font-size: 2rem !important;
                }
            }
            ion-back-button {
                .button-native {
                    padding: 0 !important;
                }
            }
            &.dark_icon {
                ion-menu-button {
                    --color: var(--black) !important;
                }
                .button-inner {
                    ion-icon {
                        color: var(--black);
                    }
                }
            }
        }
        ion-title {
            color: var(--text-black);
            padding: 0 10px !important;
            text-align: justify;
            font-size: 1.6rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            position: relative !important;
            overflow: visible !important;
            line-height: 60px;
            .end {
                margin-right: 0;
                margin-left: auto;
                display: block;
            }
        }
    }
    &.bg_transparent {
        //position: absolute;
        z-index: 999;
        width: 100%;
        height: auto;
        ion-toolbar {
            --background: transparent;
            --ion-color-base: transparent !important;
        }
    }
}

.no-padding {
    padding: 0px !important;
}

.form {
    display: block;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    width: calc(100% - 0px);
    ion-list {
        background: var(--transparent) !important;
        margin: 0;
    }
    ion-item {
        padding: 0px 0px 8px 0px;
        width: calc(100% - 40px);
        min-height: unset !important;
        box-shadow: none !important;
        margin: 0 auto;
        margin-bottom: 30px;
        --background: var(--transparent);
        align-items: flex-start;
        //border-top: 1px solid var(--ion-color-light-shade) !important;
        --inner-padding-end: 0px;
        --inner-min-height: unset !important;
        --padding-start: 0;
        --highligh-color-focused: var(--transparent) !important;
        box-shadow: none !important;
        border-bottom: 1px solid #ccc !important;
        ion-icon {
            margin: 0;
            color: var(--primary);
            font-size: 1.3rem;
            min-width: 32px;
            height: 100%;
            line-height: 103px;
            font-size: 1.55rem;
        }
        ion-label.label-stacked {
            color: var(--text-black) !important;
            margin: 0;
            font-size: 1.4rem;
            letter-spacing: 0;
            font-weight: 700;
            margin-bottom: 12px;
            max-width: 100% !important;
            width: 100% !important;
            display: block;
            flex: unset;
            transform: unset !important;
        }
        ion-textarea,
        ion-input {
            line-height: 25px;
            margin-top: 0px;
            --padding: 0 !important;
            font-size: 1.23rem !important;
            font-weight: 400 !important;
            letter-spacing: .5px;
            --padding-start: 0 !important;
            --padding-end: 0 !important;
            --padding-top: 0 !important;
            --padding-bottom: 0px !important;
            color: var(--text-black);
            --placeholder-color: var(--text-light) !important;
            --placeholder-opacity: 1;
            --placeholder-font-weight: 400 !important;
            position: relative;
            //	top: 4px;
            input {
                --padding: var(--no-padding);
                &.native-input {
                    --padding: var(--no-padding);
                }
            }
        }
        &.item-select {
            ion-select {
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                padding: 0;
                font-size: 1.23rem !important;
                font-weight: 400;
                color: var(--text-black);
                --select-icon: var(--primary) !important;
                .select-icon {
                    display: none !important;
                    --color: var(--primary) !important;
                    .select-icon-inner {
                        color: var(--primary) !important;
                    }
                }
            }
        }
        &.item-datetime {
            ion-datetime {
                padding: 0;
                font-size: 1.23rem !important;
                --placeholder-color: var(--text-light) !important;
                --placeholder-font-weight: 400 !important;
                .datetime-text {
                    color: var(--text-black);
                    font-weight: 500 !important;
                }
            }
        }
    }
    ion-row {
        margin: 0 -5px;
    }
}

.alert-wrapper {
    --background: var(--bg-color);
    .alert-head {
        h2 {
            color: var(--white);
        }
    }
    .alert-radio-group {
        .alert-radio-label {
            color: var(--white) !important;
        }
    }
}

.action-sheet-wrapper {
    --background: var(--bg-color);
    .action-sheet-container {
        .action-sheet-group {
            .action-sheet-button {
                padding: 0 20px;
                color: var(--text-dark);
                font-size: 1.2rem !important;
                &.action-sheet-selected {
                    color: var(--primary);
                }
            }
            &.action-sheet-group-cancel {
                background: var(--primary) !important;
                height: 53px;
                .action-sheet-button {
                    .action-sheet-button-inner {
                        font-size: 1.1rem;
                        font-weight: 500;
                        letter-spacing: 1px !important;
                        display: block;
                        text-align: center;
                        line-height: 53px;
                        color: var(--white);
                    }
                }
            }
        }
    }
}

ion-datetime {
    max-width: 280px;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    border-radius: 13px !important;
    min-width: calc(100% - 58px) !important;
    padding-bottom: 40px;
    // max-height: 484px !important;
    height: fit-content;
    overflow: hidden;
    &::part(time-body) {
        min-width: 50% !important;
        text-align: center!important;
        display: block!important;
        min-height: 45px!important;
        font-size: 1.2rem!important;
    }
    ion-buttons {
        padding: 0;
        display: flex !important;
        align-items: center !important;
        position: relative;
        flex-wrap: nowrap;
        transform: unset !important;
        position: absolute;
        left: 0;
        width: 100%;
        ion-button {
            color: var(--white);
            background: var(--primary);
            height: 50px !important;
            text-align: center;
            margin: 0 !important;
            width: 100%;
            min-width: 50%;
            position: relative;
            font-size: 1rem !important;
            text-transform: uppercase;
            letter-spacing: 0.7px;
            --border-radius: 0px !important;
            &:nth-child(2) {
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 1.5px;
                    height: 100%;
                    background-color: var(--white);
                    z-index: 9999;
                    margin: 0 auto;
                }
            }
        }
    }
}

ion-modal {
    ion-content {
        --background: var(--transparent);
    }
}

ion-picker {
    .picker-wrapper {
        --background: var(--bg-color);
        .picker-toolbar {
            display: flex;
            align-items: center;
            .picker-toolbar-button {
                .picker-button {
                    color: var(--primary);
                    font-size: 1.2rem !important;
                }
                &.picker-toolbar-cancel {
                    margin-left: 0;
                    margin-right: auto;
                }
            }
        }
        .picker-columns {
            ion-picker-column {
                font-size: 1.3rem !important;
                .picker-opt {
                    color: var(--text-dark);
                    &.picker-opt-selected {
                        color: var(--primary);
                    }
                }
            }
        }
    }
}

app-tabs {
    background: var(--bg-color);
}

app-add-family-member {
    ion-searchbar {
        .searchbar-input-container {
            input {
                box-shadow: none !important;
                padding: 0 !important;
                font-size: 1.23rem !important;
                font-weight: 400;
                color: var(--text-black);
            }
        }
    }
}

app-sign-in.ion-page,
app-contact-us.ion-page,
app-community-info.ion-page,
app-home.ion-page {
    background: url(assets/images/bg.png) 0 0/100% 100% no-repeat;
}

ion-modal {
    --background: rgba(0, 0, 1, 0.267) !important;
}

ion-app[dir="rtl"] {
    .d-flex .end {
        margin-left: 0;
        margin-right: auto;
    }
    ion-header ion-toolbar ion-buttons .button-inner ion-icon {
        padding-left: 0px !important;
        padding-right: 10px;
    }
    app-matrimony-profile .scroll_container .item_srcoll {
        &:first-child {
            margin-left: 5px !important;
            margin-right: 20px !important;
        }
        &:last-child {
            margin-right: 5px !important;
            margin-left: 20px !important;
        }
    }
    app-matrimony-profile .scroll_container .item_srcoll .img_box ion-icon {
        right: unset !important;
        left: 5px !important;
    }
    app-home .scroll_container .item_srcoll {
        &:first-child {
            margin-left: 5px !important;
            margin-right: 20px !important;
        }
        &:last-child {
            margin-right: 5px !important;
            margin-left: 20px !important;
        }
    }
    app-select-relation ion-header ion-list ion-item .item_container,
    app-add-family-member ion-list ion-item .item_container,
    app-blood-doners ion-list ion-item .item_container,
    app-directory ion-list ion-item .item_container {
        padding: 15px 15px 11px 11px !important;
        .img_box {
            margin-right: 0px !important;
            margin-left: 20px !important;
        }
    }
    app-matrimony ion-list ion-item .item_container {
        padding-left: 0px !important;
        padding-right: 26px !important;
        .scroll_container .item_srcoll {
            &:first-child {
                margin-right: 10px !important;
                margin-left: 5px !important;
            }
            &:last-child {
                margin-left: 26px !important;
                margin-right: 5px !important;
            }
        }
    }
    app-blogs ion-list ion-item .item_container .blog_details .img_box {
        margin-right: 0 !important;
        margin-left: 15px !important;
    }
    app-chats ion-list ion-item .item_container {
        .img_box {
            margin-right: 0px !important;
            margin-left: 20px !important;
        }
    }
    app-conversation {
        .chat_container .chat_box {
            &.send {
                margin-left: 0 !important;
                margin-right: auto !important;
                .chat {
                    padding: 16px 45px 15px 14px !important;
                    margin-left: 0 !important;
                    margin-right: auto !important;
                    h2 {
                        text-align: left !important;
                    }
                    p {
                        text-align: left !important;
                    }
                }
            }
            &.received .chat {
                padding: 16px 14px 15px 45px !important;
            }
        }
    }
    app-feed-posts ion-list,
    app-matrimony-about ion-list,
    app-user-profile ion-list,
    app-my-profile ion-list {
        ion-item .item_container .img_box {
            margin-right: 0 !important;
            margin-left: 15px !important;
        }
    }
    app-matrimony-about ion-header .option_container,
    app-user-profile ion-header .option_container,
    app-my-profile ion-header .option_container {
        right: unset !important;
        left: 22px;
    }
    app-my-profile {
        .buy_this_app {
            right: unset !important;
            left: 0px !important;
            border-radius: 0px 50px 50px 0px;
        }
    }
    ion-header ion-toolbar ion-buttons {
        ion-back-button {
            transform: rotate(180deg);
        }
    }
}